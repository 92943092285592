
























































import { Component, Prop, Vue } from 'vue-property-decorator';
import ColumnChart from '../components/column-chart.vue';
import PieChart from '../components/pie-chart.vue';
import WaterfallChart from '../components/waterfall-chart.vue';
import { MetricPage } from '../models/metricPage';
import metricText from '../data/metricText';
import ScoreData from '@/data/scoreData';

@Component({
    components: {
        ColumnChart,
        WaterfallChart,
        PieChart,
    },
})
export default class SupportScheme extends Vue {
    metric = 'effectiveDecisions';

    @Prop()
    scoreData!: ScoreData;

    metricText: Record<string, MetricPage> = metricText;

    get title (): string {
        return this.metricText[this.metric]?.title;
    }

    get circleColour () : string {
        return this.scoreData[`${this.metric}Rag`] as string;
    }

    get circleText (): string {
        switch (this.circleColour) {
        case 'red': return 'Top priority';
        case 'amber': return 'Medium priority';
        case 'green': return 'Low priority';
        default: return 'Top priority';
        }
    }

    get intro (): string {
        return this.metricText[this.metric]?.intro;
    }

    get measure (): string {
        return this.metricText[this.metric]?.measure;
    }

    get colour (): string {
        return this.metricText[this.metric]?.color;
    }

    get link (): string {
        return this.metricText[this.metric]?.link ?? '';
    }

    get link2 (): string | undefined {
        return this.metricText[this.metric]?.link2;
    }

    get graphTitle (): string | undefined {
        return this.metricText[this.metric]?.graphData?.graphTitle;
    }

    get graphType (): string | undefined {
        return this.metricText[this.metric]?.graphData?.graphType;
    }

    get graphText (): string | undefined {
        return this.metricText[this.metric]?.graphData?.graphText;
    }

    get graphData (): unknown {
        return this.metricText[this.metric]?.graphData?.graphData;
    }

    get yAxisTitle (): string | undefined {
        return this.metricText[this.metric]?.graphData?.yAxisTitle;
    }

    get plotBands (): [number, number] | undefined {
        return this.metricText[this.metric]?.graphData?.bands;
    }

    get schemePercentile (): number {
        return this.scoreData[`${this.metric}Percentile`] as number;
    }

    get hideSuffix (): boolean {
        return this.metricText[this.metric]?.graphData?.noSuffix ?? false;
    }

    goBack (): void {
        this.$router.push('/');
    }
}

export default class ScoreData {
    constructor (score: string) {
        if (!score) return;
        this.scoreString = score;
        const arr = score.split('_');

        this.version = Number(arr[0]);
        this.assetSize = Number(arr[1]);
        this.tpFundingLevel = Number(arr[2]);
        this.buyoutFundingLevel = Number(arr[3]);

        this.supportSchemeRag = this.parseColour(arr[4]);
        this.supportSchemePercentile = Number(arr[5]);

        this.tprRag = this.parseColour(arr[6]);
        this.tprPercentile = Number(arr[7]);

        this.climateRag = this.parseColour(arr[8]);
        this.climatePercentile = Number(arr[9]);

        this.investmentRiskRag = this.parseColour(arr[10]);
        this.investmentRiskPercentile = Number(arr[11]);

        this.tooLongRag = this.parseColour(arr[12]);
        this.tooLongPercentile = Number(arr[13]);

        this.missOpportunitiesRag = this.parseColour(arr[14]);
        this.missOpportunitiesPercentile = Number(arr[15]);

        this.memberLivesRag = this.parseColour(arr[16]);
        this.memberLivesPercentile = Number(arr[17]);

        this.memberOptionsRag = this.parseColour(arr[18]);
        this.memberOptionsPercentile = Number(arr[19]);

        this.cashOutflowRag = this.parseColour(arr[20]);
        this.cashOutflowPercentile = Number(arr[21]);

        this.newGovernancePercentile = Number(arr[22]);

        this.effectiveDecisionsKnowledge = Number(arr[23]);

        this.effectiveDecisionsFramework = Number(arr[24]);

        this.effectiveDecisionsAdvice = Number(arr[25]);

        this.effectiveDecisionsStakeholders = Number(arr[26]);

        this.showBands = arr[27] !== 'n';
    }

    private parseColour (colour: string): string {
        switch (colour) {
        case 'r': return 'red';
        case 'lr': return 'light-red';
        case 'a': return 'amber';
        case 'lg': return 'light-green';
        case 'g': return 'green';
        default: return 'red';
        }
    }

    [key: string]: number | string | boolean | ((colour: string) => string);

    scoreString = '';

    version = 1;

    showBands = true;

    // Front page
    assetSize = 1070;

    tpFundingLevel = 78;

    buyoutFundingLevel = 58;

    // Pink

    supportSchemeRag = 'red';

    supportSchemePercentile = 2;

    tprRag = 'amber';

    tprPercentile = 50;

    // Orange

    climateRag = 'red'

    climatePercentile = 26;

    investmentRiskRag = 'amber'

    investmentRiskPercentile = 27;

    tooLongRag = 'amber';

    tooLongPercentile = 3;

    missOpportunitiesRag = 'amber';

    missOpportunitiesPercentile = 86.5;

    // DarkBlue

    memberLivesRag = 'amber';

    memberLivesPercentile = 61;

    memberOptionsRag = 'green';

    memberOptionsPercentile = 91;

    cashOutflowRag = 'red'

    cashOutflowPercentile = 1;

    // Grey

    newGovernanceRag = 'green'

    newGovernancePercentile = 1;

    effectiveDecisionsRag = 'green'

    effectiveDecisionsPercentile = 0;

    effectiveDecisionsKnowledge = 3;

    effectiveDecisionsFramework = 2;

    effectiveDecisionsAdvice = 2;

    effectiveDecisionsStakeholders = 1;
}









import { Options } from 'highcharts';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class PieChart extends Vue {
    @Prop()
    graphData!: Array<{ name: string; y: number; id: string; color: string }>;

    @Prop()
    graphTitle!: string;

    @Prop()
    graphText!: string;

    @Prop()
    yAxisTitle!: string;

    @Prop()
    percentile!: number;

    @Prop()
    showBands!: boolean;

    colourMap: Record<string, string> = {
        '#d73c3c': '#00a6c9',
        '#dd7878': '#0095b5',
        '#f3b264': '#0085a1',
        '#faa743': '#0085a1',
        '#85c585': '#00748d',
        '#6bbd6b': '#00748d',
        '#4ca94c': '#006479',
    }

    get schemePoint (): string {
        return this.graphData[this.percentile - 1].id;
    }

    get formattedGraphData (): Array<{ name: string; y: number; id: string; color: string }> {
        return this.graphData.map((a) => ({
            ...a,
            color: this.showBands ? a.color : this.colourMap[a.color],
        }));
    }

    get options (): Options {
        return {
            chart: {
                type: 'pie',
                marginBottom: 100,
                backgroundColor: 'transparent',
            },
            colors: ['#00a3c7', '#f27174', '#faa743', '#002e5f', '#7b9fa5', '#61093f', '#b7d878', '#005c5f'],
            credits: {
                text: this.graphText,
                style: {
                    fontSize: '1rem',
                    wordWrap: 'break-word',
                    width: 500,
                    height: 60,
                    display: 'block',
                },
                position: {
                    align: 'center',
                    y: -35,
                },
                href: '',
            },
            title: {
                text: this.graphTitle,
            },
            xAxis: {
                visible: true,
                crosshair: true,
                reversed: false,
                minorTickLength: 0,
                tickLength: 0,
            },
            yAxis: {
                title: {
                    text: this.yAxisTitle,
                },
                labels: {
                    formatter () {
                        return `${this.value}%`;
                    },
                    style: {
                        fontSize: '1rem',
                    },
                },
            },
            annotations: [{
                labels: [{
                    point: this.schemePoint,
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    useHTML: true,
                    text: '<span id="yourScheme">Your scheme</span>',
                    shape: 'connector',
                    allowOverlap: true,
                    style: {
                        fontSize: '1rem',
                        padding: '10',
                    },
                }],
                labelOptions: {
                    x: 100,
                    y: -30,
                },
            }],
            legend: {
                enabled: false,
            },

            tooltip: {
                style: {
                    zIndex: 999,
                },
                formatter () {
                    return `${this.point.series.name}: <b>${this.y}%</b>`;
                },
            },
            plotOptions: {
                pie: {
                    innerSize: '60%',
                    size: '100%',
                },
            },
            series: [{
                name: this.graphTitle, type: 'pie', data: this.formattedGraphData, color: '#00a6c9',
            }] as never,
        };
    }
}

import Vue from 'vue';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import loadStock from 'highcharts/modules/stock';
import loadDrilldown from 'highcharts/modules/drilldown';
import loadHighchartsMore from 'highcharts/highcharts-more';
import loadSolidGauge from 'highcharts/modules/solid-gauge';
import annotations from 'highcharts/modules/annotations';
import store from './store';
import router from './router';
import App from './App.vue';

loadStock(Highcharts);
loadDrilldown(Highcharts);
loadHighchartsMore(Highcharts);
loadSolidGauge(Highcharts);
annotations(Highcharts);
Vue.use(HighchartsVue, { Highcharts });
Vue.config.productionTip = false;

if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations()
        .then((registrations) => {
            // eslint-disable-next-line no-restricted-syntax
            for (const registration of registrations) {
                if (registration && registration.active?.scriptURL === 'https://sonar.lcp.uk.com/service-worker.js') {
                    registration.unregister();
                }
            }
        });
}

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');

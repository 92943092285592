







import { Options } from 'highcharts';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class WaterfallChart extends Vue {
    @Prop()
    graphData!: Array<{ name: string; data: Array<number>; color: string }>;

    @Prop()
    graphTitle!: string;

    @Prop()
    graphText!: string;

    @Prop()
    yAxisTitle!: string;

    @Prop()
    percentile!: number;

    @Prop()
    showBands!: boolean;

    colourMap: Record<string, string> = {
        '#d73c3c': '#00a6c9',
        '#dd7878': '#0095b5',
        '#f3b264': '#0085a1',
        '#faa743': '#0085a1',
        '#85c585': '#00748d',
        '#6bbd6b': '#00748d',
        '#4ca94c': '#006479',
    }

    get schemePoint (): number {
        return this.percentile - 1;
    }

    get formattedGraphData (): Array<{ name: string; data: Array<number>, color: string }> {
        return this.graphData.map((a) => ({
            ...a,
            color: this.showBands ? a.color : this.colourMap[a.color],
        }));
    }

    get schemePointX (): number {
        const data: Array<number> = [];
        this.graphData.forEach(((a) => a.data.forEach((b) => {
            if (b) data.push(b);
        })));
        let sum = 0;
        data.forEach((point, i) => {
            if (this.percentile > i) {
                sum += point;
            }
            if (this.percentile === i + 1) {
                sum -= point / 2;
            }
        });
        return sum;
    }

    get options (): Options {
        return {
            chart: {
                type: 'waterfall',
                inverted: true,
                backgroundColor: 'transparent',
                spacingBottom: 60,
            },
            colors: ['#00a3c7', '#f27174', '#faa743', '#002e5f', '#7b9fa5', '#61093f', '#b7d878', '#005c5f'],
            credits: {
                text: this.graphText,
                style: {
                    fontSize: '1rem',
                    wordWrap: 'break-word',
                    width: 600,
                    height: 60,
                    display: 'block',
                },
                position: {
                    align: 'center',
                    y: -35,
                },
                href: '',
            },
            title: {
                text: this.graphTitle,
            },
            xAxis: {
                visible: false,
                reversed: true,
            },
            yAxis: {
                visible: false,
                reversed: false,
                stackLabels: {
                    enabled: true,
                    style: {
                        fontWeight: 'bold',
                    },
                },
                labels: {
                    formatter () {
                        return `${this.value}%`;
                    },
                    style: {
                        fontSize: '1rem',
                    },
                },
            },
            annotations: [{
                labels: [{
                    point: {
                        x: this.schemePoint,
                        y: this.schemePointX,
                        xAxis: 0,
                        yAxis: 0,
                    },
                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    useHTML: true,
                    text: '<span id="yourScheme">Your scheme</span>',
                    shape: 'connector',
                    allowOverlap: true,
                    style: {
                        fontSize: '1rem',
                        padding: '10',
                    },
                }],
                labelOptions: {
                    x: 40,
                    y: 50,
                },
            }],
            legend: {
                enabled: true,
                reversed: false,
                margin: 20,
                itemStyle: {
                    fontSize: '1rem',
                    fontWeight: '400',
                    fontFamily: 'Montserrat,Helvetica, sans-serif',
                },
            },
            tooltip: {
                style: {
                    zIndex: 999,
                },
                formatter () {
                    return `${this.point.series.name}: <b>${this.y}%</b>`;
                },
            },
            plotOptions: {
                waterfall: {
                    stacking: 'normal',
                    borderWidth: 0,
                    depth: 30,
                    pointWidth: 60,
                    dataLabels: {
                        enabled: true,
                        y: +4,
                        formatter () {
                            if (!this.y) return '';
                            return `<div class="scale-text">${this.y > 15 ? `${Math.round(this.y)}% <span class="hide-small">of schemes</span>` : `${Math.round(this.y)}%`}</div>`;
                        },
                        style: {
                            fontWeight: 'bold',
                            fontSize: '1.1rem',
                            textOutline: 'none',
                            color: 'white',
                        },
                    },
                    events: {
                        legendItemClick () {
                            return false;
                        },
                    },
                },
            },
            series: this.formattedGraphData as never,
        };
    }
}

import { MetricPage } from '@/models/metricPage';

const data: Record<string, MetricPage> = {
    supportScheme: {
        shortTitle: 'Sponsor unable to support scheme',
        title: 'Sponsor is unable to support scheme',
        intro: 'For many schemes, the sponsor is both the biggest support and the biggest source of risk to member outcomes. It is essential that you understand how much support is currently available, and how this might change over time. A weak covenant rating makes you more sensitive to all other risks run by the pension scheme, as there is less chance the sponsor can help make good a worsening in position due to other factors.',
        measure: 'Covenant strength as assessed at latest actuarial valuation (or more recent if available)',
        link: 'https://www.lcp.uk.com/pensions-benefits/covenant-consulting/',
        color: 'watermelon',
        hidePriority: true,
        graphData: {
            graphType: 'pie',
            graphTitle: 'Latest covenant rating, expressed on TPR’s four-point scale',
            graphData: [
                {
                    name: 'Weak', y: 9, id: '3', color: '#d73c3c',
                },
                {
                    name: 'Tending to weak', y: 22, id: '2', color: '#dd7878',
                },
                {
                    name: 'Tending to strong', y: 53, id: '1', color: '#faa743',
                },
                {
                    name: 'Strong', y: 16, id: '0', color: '#6bbd6b',
                },
            ],
            yAxisTitle: 'Worsening in funding level due to a VaR event',
            graphText: 'Split of covenant rating across UK pension schemes, according to TPR, reflecting only schemes in deficit',
        },
    },
    tpr: {
        shortTitle: 'Regulator requires additional prudence',
        title: 'The Pensions Regulator requires additional prudence',
        intro: 'The Pension Schemes Act 2021 introduces greater powers for the Pensions Regulator, ahead of a revised funding code coming into force in due course.  If the Pensions Regulator is unhappy with the approach to valuing pension scheme liabilities, and agreeing a plan to full funding with the sponsor, you might need to take a more prudent approach.  We compare your technical provisions to our best estimate of a fast track approach to indicate how you may stack up against the new funding code.  Note this comparison may change over time if your technical provisions assumptions are expected to increase in prudence over time. This metric is a subset of the metrics shown in the <a href="https://insight.lcp.uk.com/acton/media/20628/lcp-fast-track-dashboard" target="_blank">LCP Fast Track Dashboard</a> – please speak to your usual LCP adviser to see a more detailed comparison of your approach to our best estimate of fast track.  Note that green does not indicate no action required: for example, you may be sufficiently prudent but use a funding method that is not aligned with a fast track approach.',
        measure: 'Current technical provisions as a proportion of our best guess of a fast track basis (a basis which complies with all of the Pensions Regulator\'s tests under the new funding code, and may expect little scrutiny in their funding approach)',
        color: 'watermelon',
        link: 'https://insight.lcp.uk.com/acton/media/20628/lcp-fast-track-dashboard',
        graphData: {
            graphTitle: 'Current technical provisions as a proportion of potential fast track liability value',
            yAxisTitle: 'TPs as % of potential fast track TPs',
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
            graphData: [69, 80, 82, 82, 82, 85, 85, 86, 91, 91, 91, 92, 92, 92, 93, 93, 93, 93, 93, 93, 94, 94, 94, 94, 94, 94, 94, 94, 94, 94, 94, 94, 95, 95, 95, 95, 95, 95, 95, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 96, 97, 97, 97, 97, 98, 98, 98, 98, 98, 98, 98, 98, 98, 98, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 99, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 100, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 101, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 102, 103, 103, 103, 103, 103, 103, 103, 103, 103, 103, 103, 103, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 104, 105, 105, 105, 105, 105, 105, 105, 105, 105, 105, 105, 105, 106, 106, 106, 106, 106, 106, 106, 107, 107, 107, 107, 107, 107, 107, 107, 107, 107, 107, 107, 107, 108, 108, 108, 108, 108, 108.4, 109, 109, 109, 110, 110, 110, 110, 110, 111, 112, 112, 113, 114, 114, 114, 114, 114, 114, 115, 117, 118, 120],
            bands: [100, 105],
        },
    },
    climate: {
        shortTitle: 'Climate risks emerge',
        title: 'Climate risks emerge, jeopardising target',
        intro: 'Many pension schemes are considered long term investors. This means they can be among the most exposed to both the transitional and physical impacts of climate change.  The most well known climate risks (within equity markets) are just the tip of the iceberg, with potentially greater risks in credit and private markets (where data is generally sparce).  Understanding your scheme\'s climate risk profile will help you to better position to avoid losses caused by climate risks playing out, and also enable you to exploit opportunities prompted by the change that needs to occur. And it\'s not just your investments that are exposed to climate risk, you should also carefully consider whether your scheme sponsor may be a winner or a loser from the climate transition.',
        measure: 'Weighted average risk score of exposure to climate investment risks (see <a href="https://insight.lcp.uk.com/acton/attachment/20628/f-a09737e4-fa63-4845-97de-17dce827d61f/1/-/-/-/-/The tip of the iceberg - How UK institutional investors are responding to climate risks.pdf" target="_blank">link</a> to find out more about the framework we’ve used), using a 1-5 rating on different asset classes held, where 1 is significantly exposed to climate investment risk, and 5 represents exposure to opportunities as a result of the climate transition',
        color: 'grey',
        link: 'https://www.lcp.uk.com/investment/responsible-investment/',
        graphData: {
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
            graphTitle: 'Weighted average LCP climate investment risk score',
            yAxisTitle: 'Climate investment risk score',
            graphData: [0.85, 0.93, 1.08, 1.41, 1.62, 1.63, 1.83, 1.84, 1.89, 1.95, 1.98, 2.05, 2.06, 2.07, 2.09, 2.11, 2.15, 2.23, 2.23, 2.25, 2.30, 2.32, 2.34, 2.37, 2.38, 2.38, 2.38, 2.38, 2.38, 2.38, 2.39, 2.39, 2.41, 2.41, 2.43, 2.44, 2.44, 2.46, 2.46, 2.46, 2.47, 2.47, 2.48, 2.50, 2.51, 2.51, 2.51, 2.53, 2.53, 2.53, 2.55, 2.55, 2.55, 2.55, 2.56, 2.56, 2.56, 2.57, 2.57, 2.57, 2.57, 2.58, 2.58, 2.58, 2.58, 2.59, 2.59, 2.60, 2.60, 2.61, 2.62, 2.62, 2.63, 2.63, 2.64, 2.64, 2.65, 2.66, 2.66, 2.67, 2.67, 2.67, 2.67, 2.68, 2.69, 2.69, 2.70, 2.70, 2.70, 2.70, 2.70, 2.71, 2.72, 2.72, 2.73, 2.73, 2.73, 2.73, 2.73, 2.73, 2.73, 2.74, 2.74, 2.74, 2.74, 2.74, 2.75, 2.75, 2.75, 2.76, 2.77, 2.77, 2.77, 2.78, 2.78, 2.78, 2.78, 2.79, 2.80, 2.80, 2.80, 2.81, 2.81, 2.81, 2.82, 2.82, 2.82, 2.84, 2.84, 2.84, 2.86, 2.86, 2.86, 2.86, 2.87, 2.87, 2.87, 2.87, 2.88, 2.89, 2.89, 2.90, 2.91, 2.91, 2.93, 2.93, 2.94, 2.95, 2.97, 2.97, 2.97, 2.98, 2.98, 2.99, 2.99, 3.00, 3.00, 3.01, 3.01, 3.02, 3.03, 3.03, 3.03, 3.04, 3.05, 3.05, 3.07, 3.09, 3.09, 3.10, 3.10, 3.11, 3.12, 3.12, 3.13, 3.13, 3.13, 3.14, 3.14, 3.15, 3.15, 3.16, 3.17, 3.19, 3.20, 3.21, 3.23, 3.25, 3.27, 3.28, 3.31, 3.31, 3.33, 3.33, 3.33, 3.35, 3.35, 3.37, 3.42, 3.44, 3.44, 3.45, 3.46, 3.48, 3.49, 3.53, 3.58, 3.60, 3.63, 3.76],
            bands: [3, 3.5],
            noSuffix: true,
        },
    },
    investmentRisk: {
        shortTitle: 'Too much investment risk',
        title: 'We take too much investment risk and those risks emerge',
        intro: 'It is generally accepted that pension schemes will take investment risks so as to seek returns that help them achieve their long-term targets.  But taking too much risk introduces the threat that a scheme could fall behind and struggle to get back on course, if those risks materialise.  Investment risk is underwritten by the sponsor of the scheme, so it is essential to ensure the risks being taken are supported by the covenant available.  The Pensions Regulator is particularly focussed on investment risk in its latest annual statements and the new funding code.  We consider whether you are taking more risk than the Pensions Regulator might expect, given your covenant strength.',
        measure: 'Our latest estimate of the investment stress test underlying the Pensions Regulator’s fast track approach (a basis which complies with all of the Pensions Regulator’s tests under the new funding code, and may expect little scrutiny in their funding approach), as a proportion of the maximum risk allowable under a fast track approach',
        color: 'grey',
        link: 'https://www.lcp.uk.com/investment/pensions-investment/',
        graphData: {
            graphTitle: 'Assets at risk under TPR’s expected fast track stress test, compared to maximum risk under fast track regime',
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
            yAxisTitle: 'Fast track asset stress as % of expected maximum fast track risk allowable',
            graphData: [365.82, 365.78, 273.84, 264.86, 247.57, 247.22, 206.81, 204.88, 204.85, 201.22, 189.65, 167.31, 164.19, 154.28, 153.23, 146.31, 145.13, 142.22, 140.29, 137.58, 135.41, 129.68, 129.42, 129.13, 127.89, 122.30, 121.32, 121.01, 120.66, 120.46, 118.61, 117.97, 117.60, 116.48, 115.86, 114.89, 114.88, 111.48, 108.51, 108.05, 107.98, 107.63, 106.45, 105.98, 104.24, 103.77, 100.63, 100.04, 99.53, 99.44, 96.77, 96.61, 95.15, 93.85, 89.95, 88.99, 88.93, 88.70, 88.27, 84.52, 84.34, 84.04, 83.49, 83.24, 82.34, 82.24, 81.68, 80.93, 78.86, 75.16, 74.66, 74.45, 73.65, 73.60, 73.37, 73.18, 72.32, 71.55, 71.47, 70.12, 68.38, 67.57, 67.34, 66.94, 66.81, 66.78, 66.59, 66.43, 65.77, 65.07, 64.83, 64.67, 64.17, 61.91, 61.66, 61.34, 60.97, 60.44, 60.25, 59.94, 58.66, 58.23, 57.71, 56.72, 56.57, 55.91, 54.73, 54.26, 54.18, 53.67, 53.41, 52.98, 52.34, 52.30, 51.85, 50.59, 50.23, 47.05, 46.65, 45.55, 45.52, 44.22, 43.34, 43.27, 43.13, 43.09, 41.78, 40.45, 40.03, 39.99, 38.99, 38.07, 35.55, 34.73, 34.48, 33.63, 33.52, 33.38, 33.08, 32.60, 32.21, 31.74, 30.85, 30.66, 29.69, 28.28, 28.25, 27.60, 27.15, 26.54, 26.38, 26.26, 24.90, 24.82, 21.67, 21.37, 21.10, 21.07, 20.70, 18.68, 18.53, 18.04, 17.52, 17.33, 17.08, 16.95, 16.70, 16.44, 15.53, 14.61, 13.10, 10.63, 10.04, 8.89, 8.18, 8.16, 5.56, 5.41, 3.28, 2.38, 1.80, 0.72, 0.68, 0.32, -1.72, -2.35, -2.78, -2.92, -4.44, -4.70, -7.47, -9.60, -10.22, -10.32, -10.43, -11.46, -11.78, -12.43, -13.36, -13.62, -14.32, -18.09, -19.88, -24.04, -24.91, -25.15, -30.10, -30.44, -31.10, -55.92],
            bands: [100, 80],
            plotText: true,
            max: 400,
            min: -100,
        },
    },
    tooLong: {
        shortTitle: 'Too long to reach target',
        title: 'It takes too long to reach long-term target, exposing us to other risks',
        intro: 'Think of a market cycle.  Over a multi-year period we would naturally expect markets to move around, businesses to transform or fail and human behaviour to change.  The longer you plan to run risk within your investment strategy, the more likely you are to invest through risk events, which need to be managed carefully.  Layer on top of this the natural maturing of pension schemes or the risk of sponsor insolvency and you could have a toxic combination.  The Pensions Regulator has now set out clear expectations on how long risk should be taken for, and we consider your current timeframe against those expectations.',
        measure: 'How long it takes your technical provisions discount rate to reach gilts+0.5% pa, compared to the timeframe for your scheme to reach significant maturity (based on the range set out in the Pensions Regulator’s funding consultation)',
        link: 'https://www.lcp.uk.com/pensions-benefits/strategic-journey-planning-risk-management/',
        color: 'grey',
        graphData: {
            graphType: 'waterfall',
            graphText: 'Across all LCP schemes.  Uses definition of significant maturity as outlined in the Pensions Regulator\'s funding consultation.  Under this definition significant maturity is reached when duration is between 14 years and 12 years',
            graphTitle: 'Year in which technical provisions discount rate reaches gilts+0.5% pa, compared to year of significant maturity',
            graphData: [
                { name: 'Never', data: [26, 0, 0, 0, 0], color: '#d73c3c' },
                { name: 'When already significantly mature', data: [0, 17, 0, 0, 0], color: '#dd7878' },
                { name: 'May already be sigificantly mature', data: [0, 0, 14, 0, 0], color: '#f3b264' },
                { name: 'Before significant maturity', data: [0, 0, 0, 22, 0], color: '#85c585' },
                { name: 'Already funding on equivalent to gilts +0.5% pa or lower', data: [0, 0, 0, 0, 21], color: '#4ca94c' },
            ],
        },
    },
    missOpportunities: {
        shortTitle: 'Miss attractive opportunities',
        title: 'We miss attractive opportunities to secure member benefits',
        intro: 'Whether buyout is your ultimate target or not, achieving a position of low or zero reliance on a potentially changeable sponsor covenant is usually accepted as a key aim for trustees (and we have used buyout – ie passing all assets to an insurer and securing all benefits – as a proxy for this).  It’s all very well achieving a strong position, but this should prompt action to reduce investment or other ongoing risks, so as to protect the progress made.  The better funded you are on this strong basis, the more likely there is a range of actions you can take to secure member benefits.',
        measure: 'Funding level on a buyout basis',
        link: 'https://www.lcp.uk.com/pensions-benefits/buy-ins-buy-outs-longevity-swaps/',
        color: 'grey',
        graphData: {
            graphTitle: '% buyout funding level',
            graphData: [39, 45, 48, 49, 50, 50, 51, 51, 52, 53, 54, 54, 55, 57, 58, 59, 59, 60, 60, 60, 61, 62, 62, 63, 64, 64, 64, 64, 64, 64, 65, 66, 66, 66, 66, 66, 66, 67, 67, 67, 68, 68, 69, 69, 69, 70, 71, 71, 71, 71, 71, 71, 72, 72, 73, 73, 73, 73, 73, 73, 74, 74, 74, 74, 74, 74, 75, 75, 75, 75, 75, 75, 75, 76, 76, 76, 76, 76, 77, 77, 77, 77, 77, 77, 77, 77, 77, 78, 78, 79, 79, 79, 80, 80, 80, 80, 80, 81, 81, 81, 81, 81, 81, 81, 81, 81, 82, 82, 82, 83, 83, 83, 83, 83, 83, 83, 83, 84, 84, 84, 84, 85, 85, 85, 85, 85, 85, 85, 86, 86, 86, 86, 86, 86, 86, 86, 86, 87, 87, 87, 87, 87, 88, 88, 88, 88, 88, 89, 89, 89, 89, 90, 90, 90, 91, 91, 91, 91, 92, 92, 92, 92, 93, 93, 93, 93, 93, 93, 93, 93, 93, 93, 94, 94, 94, 94, 94, 94, 94, 94, 95, 95, 95, 95, 95, 96, 96, 96, 96, 98, 98, 99, 99, 100, 101, 101, 102, 103, 104, 104, 105, 105, 106, 109, 110, 110, 112, 122, 127, 132].reverse(),
            bands: [80, 50],
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
        },
    },
    memberLives: {
        shortTitle: 'Members live longer',
        title: 'Assets inadequate because members live longer than expected',
        intro: 'If members live longer than currently expected, you may find that you don\'t have enough in the scheme to meet all payments, even if you thought you were on track to your long-term target. Mitigating longevity risk helps to reduce this, but trustees tend to first focus on investment risks.  When longevity risk starts to become the most dominant risk, it may be time to take action.',
        measure: 'Proportion of gross Value at Risk (VaR – the minimum amount by which a scheme’s position may worsen, compared to the expected position, due to investment and longevity factors, in a 1-in-20 bad year), represented by longevity VaR',
        color: 'grapefruit',
        link: 'https://www.lcp.uk.com/technology-analytics/lcp-life-analytics/',
        link2: 'https://www.lcp.uk.com/pensions-benefits/buy-ins-buy-outs-longevity-swaps/',
        graphData: {
            graphTitle: 'Proportion of VaR represented by illustrative longevity VaR',
            graphData: [41, 39, 39, 37, 37, 36, 36, 36, 36, 36, 35, 35, 35, 34, 33, 33, 33, 33, 32, 32, 32, 32, 32, 31, 31, 31, 31, 31, 31, 31, 31, 31, 31, 30, 30, 30, 30, 30, 30, 30, 30, 29, 29, 29, 29, 29, 29, 29, 28, 28, 28, 28, 28, 28, 28, 28, 28, 28, 27, 27, 27, 27, 26, 26, 26, 26, 26, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 25, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 24, 23, 23, 23, 23, 23, 23, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 22, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 21, 20, 20, 20, 20, 20, 20, 19, 19, 19, 19, 19, 19, 19, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 18, 17, 17, 17, 17, 17, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 16, 15, 15, 15, 15, 15, 15, 15, 15, 15, 15, 14, 14, 14, 14, 14, 14, 13, 13, 13, 13, 13, 13, 12, 12, 12, 12, 12, 12, 12, 12, 11, 11, 11, 11, 11, 10, 10, 9, 9, 8, 7, 7, 6],
            bands: [25, 14],
            yAxisTitle: 'Longevity VaR as % of total VaR',
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
        },
    },
    memberOptions: {
        shortTitle: 'Uncertainty around member options',
        title: 'Member options mean benefits change or put pressure on liquidity',
        intro: 'If a majority of your members have already retired, the benefits payable from the scheme are better known.  This is because members who have not yet retired have more options on what to do with their pension, and a longer timeframe over which pension payments will fall due.  To better manage this process, and avoid surprises that could strain scheme liquidity, review the options you offer to members, how and when you communicate these and consider whether IFA advice will help.',
        measure: 'Proportion of liability represented by members who have not yet retired',
        color: 'grapefruit',
        link: 'https://www.lcp.uk.com/pensions-benefits/',
        graphData: {
            graphData: [99, 93, 91, 89, 87, 85, 84, 82, 81, 81, 80, 79, 78, 77, 76, 75, 75, 74, 73, 72, 70, 70, 69, 69, 68.65, 68.22, 68.1887, 67, 66, 65, 65, 65, 63, 63, 63, 63, 63, 62, 62, 62, 62, 62, 62, 60, 60, 60, 60, 59, 59, 59, 59, 59, 58, 58, 58, 58, 58, 58, 58, 57, 57, 57, 57, 57, 56, 56, 55, 55, 55, 55, 54, 54, 52, 52, 52, 52, 52, 51, 51, 51, 51, 51, 51, 50, 50, 50, 50, 50, 50, 50, 50, 50, 49, 49, 49, 49, 48, 48, 48, 48, 48, 47, 47, 47, 47, 47, 46, 46, 46, 46, 46, 46, 46, 46, 45, 45, 45, 45, 45, 45, 45, 45, 44, 44, 44, 44, 43, 43, 43, 43, 42, 42, 42, 42, 42, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 41, 40, 40, 40, 40, 40, 39, 38, 38, 38, 38, 37, 37, 37, 37, 37, 37, 36, 36, 36, 35, 35, 35, 34, 34, 34, 33, 33, 32, 32, 32, 31, 30, 30, 29, 29, 29, 29, 29, 29, 29, 28, 28, 28, 28, 28, 27, 27, 24, 24, 23, 22, 21, 21, 21, 20, 19, 18, 17, 17, 16, 12, 10, 8, 8],
            graphTitle: 'Proportion of gilts +0.5% pa liabilities represented by non-pensioners',
            bands: [50, 30],
            max: 100,
            yAxisTitle: '% non-pensioner liabilities',
            graphText: 'Across LCP schemes.  Schemes towards the left of the chart are running a higher risk in this area, whereas schemes on the right are running a lower risk, or have better risk management',
        },
    },
    cashOutflow: {
        shortTitle: 'Significant cash outflow',
        title: 'We hit significant cash outflow before achieving a strong position',
        intro: 'When a pension scheme is paying out significant amounts to pensioners, because it is very mature, it is more exposed to other risks.  For example, adverse investment markets causing the funding level to drop is a bigger problem for a very mature scheme than one where the cashflow peak is some years away (with time to make good the falls).  Aiming to achieve a strong position, supported by the recovery plan, before this point, will make for a smoother journey.',
        measure: 'Number of years between remainder of recovery plan and time to reach significant maturity',
        color: 'grapefruit',
        link: 'https://www.lcp.uk.com/pensions-benefits/strategic-journey-planning-risk-management/',
        graphData: {
            graphType: 'waterfall',
            graphTitle: 'Gap between end of current recovery plan and significant maturity, measured on the proposed fast track basis',
            graphData: [
                { name: 'Recovery plan continues after significant maturity', data: [15, 0, 0, 0], color: '#dd7878' },
                { name: 'Recovery plan ends before significant maturity (within 5 years)', data: [0, 17, 0, 0], color: '#f3b264' },
                { name: 'Recovery plan ends more than 5 years before significant maturity', data: [0, 0, 32, 0], color: '#85c585' },
                { name: 'No Recovery Plan remaining', data: [0, 0, 0, 37], color: '#4ca94c' },
            ],
            graphText: '',
        },
    },
    newGovernance: {
        shortTitle: 'Governance requirements unmanageable',
        title: 'New governance requirements unmanageable in current structure',
        intro: 'The Pensions Regulator’s new single Code of Practice (the ‘Code’) is expected to be implemented in 2022. It will consolidate 10 of the 15 existing Codes and also include requirements set out in the 2018 Governance Regulations. As a result of the single Code, new governance responsibilities for trustees of both DB and DC pension schemes (with over 100 members) will be introduced.',
        measure: 'In the boxes below we’ve set out the key items you will need to have in place to comply with the Regulator’s combined code of practice.',
        color: 'blue',
        link: 'https://www.lcp.uk.com/pensions-benefits/publications/the-pensions-regulator-s-single-code-of-practice-spotlight-on-good-governance/',
        graphData: {
            graphType: 'none',
        },
    },
    effectiveDecisions: {
        shortTitle: 'Not equipped to make decisions',
        title: 'We’re not equipped to make effective decisions',
        intro: 'The role of the trustee is complex and technically demanding with trustees facing many challenges that exceed the time available. Having the right governance framework can help trustees to react swiftly to the changing environment and operate more effectively. When seeking to improve decision making, we encourage trustees to consider four key areas.',
        measure: 'In the boxes below we’ve set out our view on some of the key elements of effective decision making. Depending on your scheme’s size and circumstances, it may be appropriate to take a proportionate approach in some of these areas.',
        color: 'blue',
        graphData: {
            graphType: 'none',
        },
    },
};

export default data;

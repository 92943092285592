









































import { Component, Vue } from 'vue-property-decorator';
import ScoreData from './data/scoreData';
import metricText from '@/data/metricText';

@Component
export default class App extends Vue {
    menuShown = false;

    animate = false;

    showPassword = true;

    password = '';

    invalidPassword = false;

    score = '';

    demo = false;

    isOldPassword = false;

    goto (path: string): void {
        this.menuShown = false;
        this.$nextTick(() => {
            if (this.score) {
                this.$router.push(`${path}?data=${this.score}`);
            } else {
                this.$router.push(path);
            }
        });
    }

    mounted (): void {
        if (this.$route.query.data) {
            this.score = this.$route.query.data as string;
        } else {
            this.demo = true;
        }

        if (process.env.NODE_ENV === 'development') {
            this.animate = false;
            this.showPassword = false;
        }
    }

    login (): void {
        if (this.password === 'Son@r1221') {
            this.showPassword = false;
            this.$nextTick(() => {
                this.animate = true;
                window.setTimeout(() => {
                    this.animate = false;
                }, 4000);
            });
        } else if (this.password === '') {
            this.isOldPassword = true;
        } else {
            this.invalidPassword = true;
        }
    }

    get parsedScore (): ScoreData {
        return new ScoreData(this.score);
    }

    get navItems (): Array<{ url: string; name: string }> {
        return Object.keys(metricText).map(((a) => ({
            url: a,
            name: metricText[a].shortTitle,
        })));
    }
}

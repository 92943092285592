
















































































import { Component, Prop, Vue } from 'vue-property-decorator';
import ScoreData from '@/data/scoreData';
import Guage from '@/components/guage.vue';

@Component({
    components: {
        Guage,
    },
})
export default class Home extends Vue {
    @Prop()
    scoreData!: ScoreData;

    @Prop()
    demo!: boolean;

    isComparing = false;

    demoAmount = 50;

    metrics = [
        { name: 'Asset size', value: `£${this.scoreData.assetSize}m` },
        { name: 'Technical provisions funding level', value: `${this.scoreData.tpFundingLevel}%` },
        { name: 'Buyout funding level', value: `${this.scoreData.buyoutFundingLevel}%` },
    ]

    groups = [
        {
            name: 'External support',
            color: 'watermelon',
            icon: '/support.svg',
            items: [
                {
                    name: 'Sponsor is unable to support scheme', color: this.scoreData.supportSchemeRag, url: 'supportScheme', percentile: this.supportSchemePercentile,
                },
                {
                    name: 'The Pensions Regulator requires additional prudence', color: this.scoreData.tprRag, url: 'tpr', percentile: this.scoreData.tprPercentile,
                },
            ],
        },
        {
            name: 'Staying on course',
            color: 'grey',
            icon: '/on-course.svg',
            items: [
                {
                    name: 'Climate risks emerge, jeopardising target', color: this.scoreData.climateRag, url: 'climate', percentile: this.scoreData.climatePercentile,
                },
                {
                    name: 'We take too much investment risk and those risks emerge', color: this.scoreData.investmentRiskRag, url: 'investmentRisk', percentile: this.scoreData.investmentRiskPercentile,
                },
                {
                    name: 'It takes too long to reach long-term target, exposing us to other risks', color: this.scoreData.tooLongRag, url: 'tooLong', percentile: this.tooLongPercentile,
                },
                {
                    name: 'We miss attractive opportunities to secure member benefits', color: this.scoreData.missOpportunitiesRag, url: 'missOpportunities', percentile: this.scoreData.missOpportunitiesPercentile,
                },
            ],
        },
        {
            name: 'Paying members',
            color: 'blue',
            icon: '/paying.svg',
            items: [
                {
                    name: 'Assets inadequate because members live longer than expected', color: this.scoreData.memberLivesRag, url: 'memberLives', percentile: this.scoreData.memberLivesPercentile,
                },
                {
                    name: 'Member options mean member support required and potential uncertainty on benefits', color: this.scoreData.memberOptionsRag, url: 'memberOptions', percentile: this.scoreData.memberOptionsPercentile,
                },
                {
                    name: 'We hit significant cash outflow before achieving a strong position', color: this.scoreData.cashOutflowRag, url: 'cashOutflow', percentile: this.cashOutflowPercentile,
                },
            ],
        },
        {
            name: 'Making decisions',
            color: 'darkBlue',
            icon: '/decisions.svg',
            items: [
                {
                    name: 'New governance requirements unmanageable in current structure',
                    url: 'newGovernance',
                    percentiles: [this.scoreData.newGovernancePercentile],
                },
                {
                    name: 'We\'re not equipped to make effective decisions',
                    url: 'effectiveDecisions',
                    counts: [3, 3, 3, 2],
                    percentiles: [this.scoreData.effectiveDecisionsKnowledge, this.scoreData.effectiveDecisionsFramework, this.scoreData.effectiveDecisionsAdvice, this.scoreData.effectiveDecisionsStakeholders],
                    names: ['Knowledge', 'Framework', 'Advice', 'Stakeholders'],
                },
            ],
        },
    ]

    gotoPage (url: string): void {
        if (this.scoreData.scoreString) {
            this.$router.push(`/${url}?data=${this.scoreData.scoreString}`);
        } else {
            this.$router.push(`/${url}`);
        }
    }

    get supportSchemePercentile (): number {
        switch (this.scoreData.supportSchemePercentile) {
        case 1: return 0;
        case 2: return 33;
        case 3: return 66;
        case 4: return 100;
        default: return 0;
        }
    }

    get tooLongPercentile (): number {
        switch (this.scoreData.tooLongPercentile) {
        case 1: return 0;
        case 2: return 25;
        case 3: return 50;
        case 4: return 75;
        case 5: return 100;
        default: return 0;
        }
    }

    get cashOutflowPercentile (): number {
        switch (this.scoreData.cashOutflowPercentile) {
        case 1: return 0;
        case 2: return 50;
        case 3: return 75;
        case 4: return 100;
        default: return 0;
        }
    }
}

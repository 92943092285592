







import { Options } from 'highcharts';
import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({
    components: {
    },
})
export default class ColumnChart extends Vue {
    @Prop()
    graphData!: Array<number>;

    @Prop()
    graphTitle!: string;

    @Prop()
    graphText!: string;

    @Prop()
    yAxisTitle!: string;

    @Prop()
    percentile!: number;

    @Prop()
    plotBands!: [number, number];

    @Prop()
    showBands!: boolean;

    @Prop()
    hideSuffix!: boolean;

    @Prop()
    plotText!: boolean;

    @Prop()
    chartMin!: number | undefined;

    @Prop()
    chartMax!: number | undefined;

    bandColours = [
        '#de5e5e',
        '#faa743',
        '#6bbd6b',
    ]

    get bandedGraphData (): Array<unknown> {
        return this.graphData.map((a) => ({
            color: this.getColour(a),
            y: a,
        }));
    }

    get schemePoint (): number {
        return Math.floor(this.graphData.length * (this.percentile / 100));
    }

    get schemePointHeight (): number {
        return this.graphData[this.schemePoint];
    }

    getColour (y: number): string {
        if (!this.showBands) return '#00a6c9';
        let isReversed = false;
        if (this.plotBands[1] < this.plotBands[0]) isReversed = true;
        for (let i = 0; i < this.plotBands.length; i += 1) {
            if (isReversed) {
                if (y > (this.plotBands[i])) return this.bandColours[i] as string;
            } else if (y < (this.plotBands[i])) return this.bandColours[i] as string;
        }
        return this.bandColours[2];
    }

    get options (): Options {
        const hideSuffix = this.hideSuffix;
        return {
            chart: {
                type: 'column',
                marginBottom: 120,
                backgroundColor: 'transparent',
            },
            credits: {
                text: this.graphText,
                style: {
                    fontSize: '1rem',
                    wordWrap: 'break-word',
                    width: 500,
                    height: 60,
                    display: 'block',
                },
                position: {
                    align: 'center',
                    y: -35,
                },
                href: '',
            },
            title: {
                text: this.graphTitle,
            },
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            xAxis: {
                visible: this.plotText,
                crosshair: true,
                reversed: false,
                minorTickLength: 0,
                tickLength: 0,
                labels: {
                    enabled: false,
                },
                plotBands: [{
                    color: 'transparent',
                    from: 150,
                    to: 201,
                    label: {
                        useHTML: true,
                        text: '<span>Some schemes have a negative investment test, because they hold a very low risk portfolio and have liability hedging more than 100% on a technical provisions basis, because they have aligned hedging to a stronger target.</span>',
                        align: 'right',
                        y: +260,
                        x: 0,
                        style: {
                            padding: '10px',
                            width: '350px',
                            color: '#666',
                            height: '200px',
                            whiteSpace: 'pre-wrap',
                            textAlign: 'center',
                            zIndex: '2',
                            backgroundColor: 'rgba(255, 255, 255, 0.2)',
                            fontSize: '0.8rem',
                        },
                    },
                }],
            },
            yAxis: {
                title: {
                    text: this.yAxisTitle,
                },
                labels: {
                    formatter () {
                        return `${this.value}${hideSuffix ? '' : '%'}`;
                    },
                    style: {
                        fontSize: '1rem',
                    },
                },
                startOnTick: !(this.chartMin && this.chartMax),
                max: this.chartMax,
                min: this.chartMin,
            },
            annotations: [{
                labels: [{
                    point: {
                        x: this.schemePoint,
                        y: this.schemePointHeight,
                        xAxis: 0,
                        yAxis: 0,
                    },

                    backgroundColor: 'rgba(0, 0, 0, 0.7)',
                    borderColor: 'rgba(0, 0, 0, 0.7)',
                    useHTML: true,
                    text: '<span id="yourScheme">Your scheme</span>',
                    shape: 'connector',
                    allowOverlap: true,
                    style: {
                        fontSize: '1rem',
                        padding: '10',
                    },
                }],
                labelOptions: {
                    x: 40,
                    y: -30,
                },
            }],
            legend: {
                enabled: false,
            },

            tooltip: {
                style: {
                    zIndex: 999,
                },
                formatter () {
                    return `${this.point.series.name}: <b>${this.y}${hideSuffix ? '' : '%'}</b>`;
                },
            },
            plotOptions: {
                column: {
                    // borderWidth: 0,
                    borderColor: '#eee',
                },
            },
            series: [{
                name: this.graphTitle, type: 'column', data: this.bandedGraphData, color: '#00a6c9',
            }] as never,
        };
    }
}

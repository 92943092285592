










































import {
    Component, Prop, Vue, Watch,
} from 'vue-property-decorator';

@Component
export default class Gauge extends Vue {
    @Prop()
    originalAmount!: number;

    @Prop()
    amountChanged!: number;

    @Prop()
    demo!: boolean;

    @Prop({ default: 43 })
    height!: number;

    @Prop({ default: 45 })
    width!: number;

    isMounted = false;

    get formattedChangeAmount (): string {
        const number = Math.abs(this.amountChanged || 0).toLocaleString() ?? '0';
        const arr = number.split('.');
        return arr[0];
    }

    mounted (): void {
        this.$nextTick(() => {
            this.createArc('arc', [0, 0], 250, -130, 130, 30, 35);
            // if (this.demo) {
            this.createArc('arc2', [0, 0], 250, -130, 260 - 130, 30, 35);
            // } else {
            //     this.createArc('arc2', [0, 0], 250, -130, this.percentChange * 260 - 130, 30, 35);
            // }
        });
        window.setTimeout(() => {
            this.isMounted = true;
        }, 100);
    }

    RAD_DEG = Math.PI / 180.0;

    PI2 = 2 * Math.PI;

    get percentChange (): number {
        return ((this.amountChanged) / this.originalAmount) || 0;
    }

    get dialAngle (): number {
        return this.isMounted ? this.percentChange * 260 - 130 : 0;
    }

    getClockMarkerPos (index: number): unknown {
        const minDeg = (index / 12) * 360;
        const minRad = minDeg * (Math.PI / 180);
        const x = this.width * 0.33 * Math.cos(minRad) + (this.width * 0.58);
        const y = this.height * 0.33 * Math.sin(minRad) + (this.height * 0.9);
        return { transform: `translateX(${x}px) translateY(${y}px) rotate(${minDeg - 90}deg)` };
    }

    describeArc (center: number[], radius: number, startAngle: number, endAngle: number): string {
        const start = (this.pointOnArc)(center, radius, endAngle);
        const end = (this.pointOnArc)(center, radius, startAngle);

        const arcSweep = endAngle - startAngle < 180 ? 0 : 1;

        return ['M', start[0], start[1], 'A', radius, radius, 0, arcSweep, 0, end[0], end[1]].join(' ');
    }

    createArc (ref: string, c: Array<number>, radius: number, start: number, angle: number, width: number, corner: number): void {
        (this.$refs[ref] as SVGElement).setAttribute('d', this.arc(c, radius, start, angle, width, corner, false) as string);
    }

    pointOnArc (center: Array<number>, R: number, angle: number): number[] {
        const radians = (angle - 90) * this.RAD_DEG;

        return [center[0] + R * Math.cos(radians), center[1] + R * Math.sin(radians)];
    }

    drawCircle (center: Array<number>, R: number, width: number): Array<unknown> {
        const innerR = R - width;

        const x = center[0];
        const y = center[1];

        return ['M', x - R, y, 'A', R, R, 0, 1, 0, x + R, y, 'A', R, R, 0, 1, 0, x - R, y, 'M', x - innerR, y, 'A', innerR, innerR, 0, 1, 0, x + innerR, y, 'A', innerR, innerR, 0, 1, 0, x - innerR, y, 'Z'];
    }

    arc (center: Array<number>, R: number, start: number, end: number, w: number, corner: number, returnPoints: boolean): string | Array<unknown> {
        let points = [];
        if (Math.abs(end - start) === 360) {
            points = this.drawCircle(center, R, w);
            return returnPoints ? points : points.join(' ');
        }

        const innerR = R - w;
        const circumference = Math.abs(end - start);
        // eslint-disable-next-line no-param-reassign
        corner = Math.min(w / 2, corner);

        if (360 * (corner / (Math.PI * (R - w))) > Math.abs(start - end)) {
            // eslint-disable-next-line no-param-reassign
            corner = (circumference / 360) * innerR * Math.PI;
        }

        // inner and outer radiuses
        const innerR2 = innerR + corner;
        const outerRadius = R - corner;

        // butts corner points
        const oStart = this.pointOnArc(center, outerRadius, start);
        const oEnd = this.pointOnArc(center, outerRadius, end);

        const iStart = this.pointOnArc(center, innerR2, start);
        const iEnd = this.pointOnArc(center, innerR2, end);

        const iSection = 360 * (corner / (this.PI2 * innerR));
        const oSection = 360 * (corner / (this.PI2 * R));

        // arcs endpoints
        const iArcStart = this.pointOnArc(center, innerR, start + iSection);
        const iArcEnd = this.pointOnArc(center, innerR, end - iSection);

        const oArcStart = this.pointOnArc(center, R, start + oSection);
        const oArcEnd = this.pointOnArc(center, R, end - oSection);

        const arcSweep1 = circumference > 180 + 2 * oSection ? 1 : 0;
        const arcSweep2 = circumference > 180 + 2 * iSection ? 1 : 0;

        points = [
            // begin path
            'M', oStart[0], oStart[1],
            // outer start corner
            'A', corner, corner, 0, 0, 1, oArcStart[0], oArcStart[1],
            // outer main arc
            'A', R, R, 0, arcSweep1, 1, oArcEnd[0], oArcEnd[1],
            // outer end corner
            'A', corner, corner, 0, 0, 1, oEnd[0], oEnd[1],
            // end butt
            'L', iEnd[0], iEnd[1],
            // inner end corner
            'A', corner, corner, 0, 0, 1, iArcEnd[0], iArcEnd[1],
            // inner arc
            'A', innerR, innerR, 0, arcSweep2, 0, iArcStart[0], iArcStart[1],
            // inner start corner
            'A', corner, corner, 0, 0, 1, iStart[0], iStart[1], 'Z', // end path
        ];

        return returnPoints ? points : points.join(' ');
    }
}

import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Home from '../views/Home.vue';
import MetricPage from '../views/MetricPage.vue';
import HowToUse from '../views/HowToUse.vue';
import NewGovernance from '../views/NewGovernance.vue';
import EffectiveDecisions from '../views/EffectiveDecisions.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Home,
    },
    {
        path: '/usingLCPSonar',
        name: 'usingLCPSonar',
        props: true,
        component: HowToUse,
    },
    {
        path: '/effectiveDecisions',
        name: 'effectiveDecisions',
        props: true,
        component: EffectiveDecisions,
    },
    {
        path: '/newGovernance',
        name: 'newGovernance',
        props: true,
        component: NewGovernance,
    },
    {
        path: '/:metric',
        name: 'MetricPage',
        props: true,
        component: MetricPage,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
